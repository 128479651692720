import LehenDestakatua from './LehenDestakatua';
const LehenDestakatuaBlockView = (props) => {
    const { data, editable } = props;
    return (
        <LehenDestakatua
            kokapena={data.kokapena}
            telefonoa={data.telefonoa}
            email={data.email}
            image={data.image}
            title={data.title}
            info={data.info}
            isEditMode={editable}
        ></LehenDestakatua>
    );
};

export default LehenDestakatuaBlockView;
