import React from 'react';
import LankuButton from './LankuButton';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { LankuButtonSchema } from 'volto-lanku/components/Blocks/LankuButtonBlock/LankuButtonSchema';
import config from '@plone/volto/registry';

const LankuButtonBlockEdit = (props) => {
    const { block, data, onChangeBlock, selected } = props;
    return (
        <>
            <LankuButton variation={data?.variation} size={data?.size}>
                {data.title || 'Text example...'}
            </LankuButton>

            <SidebarPortal selected={selected}>
                <BlockDataForm
                    schema={LankuButtonSchema(
                        config,
                        data.hasButton,
                        data.hasLocationInfo,
                    )}
                    title="Lanku Button block"
                    onChangeField={(id, value) => {
                        onChangeBlock(block, {
                            ...data,
                            [id]: value,
                        });
                    }}
                    formData={data}
                    block={block}
                />
            </SidebarPortal>
        </>
    );
};

export default LankuButtonBlockEdit;
