/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
/**
 * BertsolariaView view component class.
 * @function BertsolariaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const BertsolariaView = ({ content }) => {
    const remoteUrl = content.redirect_url;
    const token = useSelector((state) => state.userSession?.token);
    React.useEffect(() => {
        if (!token) {
            window.location.href = remoteUrl;
        }
    }, [token, remoteUrl]);

    return !token ? (
        // <Redirect to={remoteUrl} />
        <p>
            <FormattedMessage id="Kargatzen..." defaultMessage="Kargatzen..." />
        </p>
    ) : (
        <Container className="view-wrapper grid stackable">
            {remoteUrl && (
                <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={8}>
                        {content.title && (
                            <h1 className="documentFirstHeading">
                                {content.title}
                            </h1>
                        )}
                        <p>
                            <FormattedMessage
                                id="The link address is:"
                                defaultMessage="Helbide honetara berbideratuko da: "
                            />
                            <UniversalLink href={remoteUrl}>
                                {flattenToAppURL(remoteUrl)}
                            </UniversalLink>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            )}
        </Container>
    );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
BertsolariaView.propTypes = {
    content: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        text: PropTypes.shape({
            data: PropTypes.string,
        }),
    }).isRequired,
};

export default BertsolariaView;
