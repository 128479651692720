import React from 'react';
import { Button } from 'semantic-ui-react';

const BuletinForm = (props) => {
    const { searchText, buttonText, isEditMode } = props;
    var SearchText = searchText || '';

    function handleChange(event) {
        SearchText = event.target.value;
    }

    function handlePost(event) {
        event.preventDefault();
        window.location.href =
            '/en/dataset-catalog/?SearchableText=' + SearchText;
    }
    function handleEdit(event) {
        event.preventDefault();
    }

    return (
        <div className="home-datasets-container">
            <form className="search-form">
                <input
                    value={searchText}
                    onChange={handleChange}
                    type="text"
                    id="buletin_form"
                    name="buletinForm"
                    className="buletinFormInput"
                />
                <Button
                    className="buletinFormButton"
                    type="submit"
                    aria-label="Search"
                    onClick={isEditMode ? handleEdit : handlePost}
                >
                    {buttonText}
                </Button>
            </form>
        </div>
    );
};
export default BuletinForm;
