import React from 'react';
import LankuButton from './LankuButton';

const LankuButtonBlockView = (props) => {
    const { data } = props;
    return (
        <div>
            <LankuButton
                variation={data?.variation}
                size={data?.size}
                link={'' + data?.link?.[0]?.['@id']}
                linkElementType={data?.link?.[0]?.['@type']}
            >
                {data.title || 'Text example...'}
            </LankuButton>
        </div>
    );
};

export default LankuButtonBlockView;
