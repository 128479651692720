import React from 'react';

const Video = ({ src, className, poster, ...props }) => {
    return src ? (
        <video className={className} controls poster={poster}>
            <source src={src} type="video/mp4" />
        </video>
    ) : (
        <p>Bideo fitxategiaren helbidea falta da</p>
    );
};

export default Video;
