import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { LehenDestakatuaSchema } from './LehenDestakatuaSchema';
import LehenDestakatua from './LehenDestakatua';

const LehenDestakatuaBlockEdit = (props) => {
    const { block, data, onChangeBlock, selected, editable } = props;
    return (
        <>
            <LehenDestakatua
                kokapena={data.kokapena}
                telefonoa={data.telefonoa}
                email={data.email}
                image={data.image}
                title={data.title}
                info={data.info}
                isEditMode={editable}
            ></LehenDestakatua>
            <SidebarPortal selected={selected}>
                <BlockDataForm
                    schema={LehenDestakatuaSchema(config)}
                    title="Destakatua blokea"
                    onChangeField={(id, value) => {
                        onChangeBlock(block, {
                            ...data,
                            [id]: value,
                        });
                    }}
                    formData={data}
                    block={block}
                />
            </SidebarPortal>
        </>
    );
};

export default LehenDestakatuaBlockEdit;
