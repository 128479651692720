import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
// import config from '@plone/volto/registry';
import { Image } from 'semantic-ui-react';
import rightArrow from 'volto-lanku/../theme/site/assets/images/right-arrow.svg';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers';
import Picture from 'volto-lanku/components/Picture/Picture';
import LankuButton from 'volto-lanku/components/Blocks/LankuButtonBlock/LankuButton';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const messages = defineMessages({
    startTime: {
        id: '{date} -tik',
        defaultMessage: '{date} -tik',
    },
});

const AlbisteakListing = ({
    items,
    moreLinkText,
    moreLinkUrl,
    header,
    headerUrl,
    headerTag,
    isEditMode,
}) => {
    let moreLink = null;
    let moreHref = moreLinkUrl?.[0]?.['@id'] || '';
    const HeaderTag = headerTag ? headerTag : 'h3';
    if (isInternalURL(moreHref)) {
        moreLink = (
            <ConditionalLink
                to={flattenToAppURL(moreHref)}
                condition={!isEditMode}
            >
                <span className="listing-footer-text">
                    {moreLinkText || moreHref}
                </span>
                <span className="right-arrow">
                    <Image
                        src={rightArrow}
                        alt={'Albiste gehiago adierazten duen irudia'}
                        width="50"
                        height="50"
                    />
                </span>
            </ConditionalLink>
        );
    } else if (moreHref) {
        moreLink = <a href={moreHref}>{moreLinkText || moreHref}</a>;
    }

    let headerLink = null;
    let headerHref = headerUrl?.[0]?.['@id'] || '';
    if (isInternalURL(headerHref)) {
        headerLink = (
            <ConditionalLink
                to={flattenToAppURL(headerHref)}
                condition={!isEditMode}
            >
                {header || headerHref}
            </ConditionalLink>
        );
    } else if (headerHref) {
        moreLink = <a href={headerHref}>{moreLinkText || headerHref}</a>;
    }
    return (
        <>
            <HeaderTag className="listing-header">
                {headerLink ? headerLink : header}
            </HeaderTag>
            <div className="container">
                <div className="albisteak-grid-container">
                    {items.map((item) => (
                        <div
                            className="albistea-grid-element"
                            key={item['@id']}
                        >
                            <div className="albistea-grid-image">
                                {!item.image_field && (
                                    <ConditionalLink
                                        item={item}
                                        condition={!isEditMode}
                                    >
                                        <Image
                                            src={DefaultImageSVG}
                                            alt="This content has no image, this is a default placeholder."
                                            size="small"
                                        />
                                    </ConditionalLink>
                                )}
                                {item.image_field && (
                                    <ConditionalLink
                                        item={item}
                                        condition={!isEditMode}
                                    >
                                        <Picture
                                            source="grid"
                                            imageBase={`${item['@id']}/@@images/image`}
                                            alt={item.title}
                                        ></Picture>
                                    </ConditionalLink>
                                )}
                            </div>
                            <div className="albistea-grid-content">
                                <h3>{item.title}</h3>
                                <p className="documentDescription">
                                    {item.description}
                                </p>
                                <LankuButton
                                    variation={'secondary'}
                                    size={'big'}
                                    link={item['@id']}
                                >
                                    Irakurri gehiago
                                </LankuButton>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {moreLink && <div className="listing-footer">{moreLink}</div>}
        </>
    );
};
AlbisteakListing.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default AlbisteakListing;
