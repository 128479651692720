import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

function LankuButton(props) {
    let { variation, link, size, children, linkElementType } = props;

    if (linkElementType === 'Image') {
        return (
            <a
                href={`${link}/@@images/image`}
                download
                className={`ui ${variation} button ${size}`}
            >
                {children}
            </a>
        );
    } else if (linkElementType === 'File') {
        return (
            <a
                href={`${link}/@@download/file`}
                download={true}
                className={`ui ${variation} button ${size}`}
            >
                {children}
            </a>
        );
    } else {
        return (
            <div>
                <Button
                    href={link}
                    className={` ${variation}`}
                    size={size}
                    as="a"
                >
                    {children}
                </Button>
            </div>
        );
    }
}

LankuButton.propTypes = {
    variation: PropTypes.string,
    link: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node,
};
export default LankuButton;
