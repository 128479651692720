import { defineMessages, useIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { UniversalLink, MaybeWrap } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getTeaserImageURL } from '@kitconcept/volto-blocks-grid/components/Teaser/utils';
import Picture from 'volto-lanku/components/Picture/Picture';

const messages = defineMessages({
    PleaseChooseContent: {
        id: 'Please choose an existing content as source for this element',
        defaultMessage:
            'Please choose an existing content as source for this element',
    },
});

const ImageLink = (props) => {
    const { data, editable } = props;
    const intl = useIntl();
    const href = data.href?.[0];
    const image = data.preview_image?.[0];
    const isEditMode = editable;
    return (
        <>
            {!href && isEditMode && (
                <Message>
                    <div className="grid-teaser-item placeholder">
                        <img src={imageBlockSVG} alt="" />
                        <p>
                            {intl.formatMessage(messages.PleaseChooseContent)}
                        </p>
                    </div>
                </Message>
            )}
            {href && (
                <div className="grid-teaser-item default">
                    <MaybeWrap
                        condition={!isEditMode}
                        as={UniversalLink}
                        href={href['@id']}
                        target={data.openLinkInNewTab ? '_blank' : null}
                    >
                        {(href.hasPreviewImage ||
                            href.image_field ||
                            image) && (
                            <div className="teaser-image">
                                <img
                                    src={flattenToAppURL(
                                        getTeaserImageURL(href, image),
                                    )}
                                    alt=""
                                    loading="lazy"
                                />
                            </div>
                        )}
                    </MaybeWrap>
                    <div className="teaser-content">
                        {data?.head_title && <h2>{data?.head_title}</h2>}

                        <h3>{data?.title}</h3>
                        {!data.hide_description && <p>{data?.description}</p>}
                    </div>
                </div>
            )}
        </>
    );
};
export default ImageLink;
