import React from 'react';
import BuletinForm from './BuletinForm';

const BuletinFormBlockView = (props) => {
    const { data, editable } = props;

    return (
        <BuletinForm
            buttonText={data.buttonText}
            isEditMode={editable}
        ></BuletinForm>
    );
};
export default BuletinFormBlockView;
