import React, { useState } from 'react';
import { Checkbox, List } from 'semantic-ui-react';
import { structure_taxonomy_terms } from 'volto-lanku/helpers/taxonomy_utils';

import {
    selectFacetSchemaEnhancer,
    selectFacetStateToValue,
    selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';
import { checkAllChildren } from './utils';

const hasAllChildrensSelected = (value, childrens) => {
    var result = true;
    if (!childrens || childrens.length === 0) {
        result = false;
    }
    childrens.forEach((ch) => {
        if (value.filter((v) => v.value === ch.value).length === 0) {
            result = false;
        }
    });
    return result;
};
const bertso_saioak_order = [
    '18e5uvmu4j', // Jaialdiak
    'xm2dd8jyqh', // Librekoak
    'u4f2ou7tdm', // Poteo/Bazkari/Afari
    't0bbrkimqc', // Musikatuak
    '1wksc7f7bh', // Literarioak
    'ijob5tbeb1', // Tematikoak
    'brsqhpib5n', // Klown saioak
    'wamwvyvkl1', // Haurrentzako saioak
    '7ut1vwdenm', // Neurrira egindakoak
];
const emanaldi_bereziak_order = [
    '0ubrhmrvko', // Emanaldi berriak 2023
    'v7dpq4hbbk', // Bertsoa eta musika
    '6fsgr8gdj4', // Bertsoa eta antzerkia
    '7wcc92r59k', // Bertsoa eta dantza
    'qmpiikap14', // Bertso saio literarioak
    'iflvspal5v', // Haurren ikuskizunak
    '7aw1uvi20f', // Bestelakoak
];

const CheckBoxTreeFacet = (props) => {
    let { facet, choices, onChange, value } = props;
    const facetValue = value;
    var [open, setOpen] = useState(false);
    let options = [];

    if (choices?.length > 0) {
        options = structure_taxonomy_terms(choices);
    }
    if (facet.field.value === 'taxonomy_bertso_saioak') {
        options.sort(
            (a, b) =>
                bertso_saioak_order.indexOf(a.value) -
                bertso_saioak_order.indexOf(b.value),
        );
    }
    if (facet.field.value === 'taxonomy_emanaldi_bereziak') {
        options.sort(
            (a, b) =>
                emanaldi_bereziak_order.indexOf(a.value) -
                emanaldi_bereziak_order.indexOf(b.value),
        );
    }
    return (
        <fieldset className="fieldset">
            <div
                className="expandable__button"
                aria-expanded={open}
                onClick={() => setOpen(!open)}
                onKeyDown={() => setOpen(!open)}
                tabIndex={0}
                role={'button'}
            >
                <legend className="form-legend">{facet.title}</legend>
            </div>
            <div className="checkbox-facet">
                <List>
                    {options.map((option) => (
                        <CheckboxListParent
                            option={option}
                            key={option.value}
                            onChange={onChange}
                            value={facetValue}
                            id={facet.field.value}
                        />
                    ))}
                </List>
            </div>
        </fieldset>
    );
};

const CheckboxListParent = ({ option, key, onChange, value, id }) => {
    return (
        <List.Item
            key={key}
            style={{
                paddingLeft: '15px',
                paddingBottom: '15px',
                paddingTop: '10px',
            }}
        >
            <List.Content>
                <List.Header>
                    {option.childrens.length > 0 ? (
                        <Checkbox
                            key={option.value}
                            name={`field-${option.value}`}
                            onChange={(event, { checked }) => {
                                checked
                                    ? onChange(id, [
                                          // if this option has children, check them all
                                          ...checkAllChildren(
                                              value,
                                              option,
                                          ).map((f) => f.value),
                                      ])
                                    : onChange(id, [
                                          ...value
                                              .filter(
                                                  (item) =>
                                                      item.value !==
                                                      option.value,
                                              )
                                              .filter(
                                                  (item) =>
                                                      option.childrens?.length >
                                                          0 &&
                                                      !option.childrens
                                                          .map((ch) => ch.value)
                                                          .includes(item.value),
                                              )
                                              .map((f) => f.value),
                                      ]);
                            }}
                            label={
                                <label htmlFor={`field-${option.value}`}>
                                    {option.label}
                                </label>
                            }
                            checked={
                                value.some(
                                    (item) => item.value === option.value,
                                ) ||
                                hasAllChildrensSelected(value, option.childrens)
                            }
                            value={option.value}
                        />
                    ) : (
                        <Checkbox
                            key={option.value}
                            name={`field-${option.value}`}
                            onChange={(event, { checked }) => {
                                checked
                                    ? onChange(id, [
                                          // if this option has children, check them all
                                          ...checkAllChildren(
                                              value,
                                              option,
                                          ).map((f) => f.value),
                                      ])
                                    : onChange(id, [
                                          ...value
                                              .filter(
                                                  (item) =>
                                                      item.value !==
                                                      option.value,
                                              )
                                              .map((f) => f.value),
                                      ]);
                            }}
                            label={
                                <label htmlFor={`field-${option.value}`}>
                                    {option.label}
                                </label>
                            }
                            checked={
                                value.some(
                                    (item) => item.value === option.value,
                                ) ||
                                hasAllChildrensSelected(value, option.childrens)
                            }
                            value={option.value}
                        />
                    )}
                </List.Header>
                {option.childrens.length > 0 && (
                    <List.Item>
                        <List.List style={{ paddingLeft: '25px' }}>
                            {option.childrens.map((child) => (
                                <List.Item>
                                    <List.Content>
                                        <List.Header>
                                            <Checkbox
                                                key={child.value}
                                                name={`field-${child.value}`}
                                                disabled={false}
                                                onChange={(
                                                    event,
                                                    { checked },
                                                ) => {
                                                    checked
                                                        ? onChange(id, [
                                                              ...value
                                                                  .filter(
                                                                      (f) =>
                                                                          f.value !==
                                                                          child.value,
                                                                  )
                                                                  .map(
                                                                      (f) =>
                                                                          f.value,
                                                                  ),
                                                              ...(checked
                                                                  ? [
                                                                        child.value,
                                                                    ]
                                                                  : []),
                                                          ])
                                                        : onChange(id, [
                                                              ...value
                                                                  .filter(
                                                                      (item) =>
                                                                          item.value !==
                                                                          child.value,
                                                                  )
                                                                  .map(
                                                                      (f) =>
                                                                          f.value,
                                                                  ),
                                                          ]);
                                                }}
                                                label={
                                                    <label
                                                        htmlFor={`field-${child.value}`}
                                                    >
                                                        {child.label}
                                                    </label>
                                                }
                                                checked={value.some(
                                                    (item) =>
                                                        item.value ===
                                                        child.value,
                                                )}
                                                value={child.value}
                                            />
                                        </List.Header>
                                    </List.Content>
                                </List.Item>
                            ))}
                        </List.List>
                    </List.Item>
                )}
            </List.Content>
        </List.Item>
    );
};

CheckBoxTreeFacet.schemaEnhancer = selectFacetSchemaEnhancer;
CheckBoxTreeFacet.stateToValue = selectFacetStateToValue;
CheckBoxTreeFacet.valueToQuery = selectFacetValueToQuery;
export default CheckBoxTreeFacet;
