import React from 'react';
import Picture from 'volto-lanku/components/Picture/Picture';
import Video from 'volto-lanku/components/Video/Video';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

const EskaintzaVideosBlockView = (props) => {
    const { data, isEditMode = false } = props;
    const content = useSelector((state) => state.content.data);
    /* const videotest = [
        {
            url:
                'https://bideoak-bertsoaeus.nr-md.com/2022/BTN22/elkarrizketak/Elk_bizkarra_ona.mp3.mp4',
            image_poster:
                'https://static-bertsoaeus.nr-md.com/media/photologue/photos/cache/1_lujanbio_3fpIVYV_content.jpg',
        },
    ]; */
    return (
        <>
            <div className="eskaintza-related-videos">
                {!content?.videos && isEditMode && (
                    <div>Ez dago bideo erlazionaturik</div>
                )}

                {content?.videos?.items && content.videos.items.length > 0 && (
                    <Grid columns={3} stackable>
                        {content.videos.items.slice(0, 3).map((video, key) => (
                            <Grid.Column key={key} width={4}>
                                <div className="eskaintza-related-video">
                                    <Video
                                        src={video['url']}
                                        poster={video['image_poster']}
                                        ///poster={videotest[0]['image_poster']}
                                    ></Video>
                                </div>
                            </Grid.Column>
                        ))}
                    </Grid>
                )}
            </div>
        </>
    );
};

export default EskaintzaVideosBlockView;
