import { Button, Grid, Segment, Image } from 'semantic-ui-react';
import {
    Facets,
    SearchDetails,
    SortOn,
} from '@plone/volto/components/manage/Blocks/Search/components';
import { defineMessages, useIntl } from 'react-intl';
import FilterList from './FilterList';
import FiltersModal from './FiltersModal';
import SearchInput from './SearchInput';
import { Icon } from '@plone/volto/components';
import React from 'react';
import filterSVG from '@plone/volto/icons/filter.svg';
import { flushSync } from 'react-dom';
import rightArrowColor from 'volto-lanku/../theme/site/assets/images/right-arrow_color.svg';

const messages = defineMessages({
    searchButtonText: {
        id: 'Search',
        defaultMessage: 'Search',
    },
});

const FacetWrapper = ({ children }) => (
    <Segment basic className="facet">
        {children}
    </Segment>
);

function setFacetsHandler(setFacets, onTriggerSearch, searchedText) {
    return (f) => {
        flushSync(() => {
            setFacets(f);
            onTriggerSearch(searchedText || '', f);
        });
    };
}

const LankuFacets = (props) => {
    const {
        children,
        data,
        totalItems,
        facets,
        setFacets,
        setSortOn,
        setSortOrder,
        sortOn,
        sortOrder,
        onTriggerSearch,
        searchedText, // search text for previous search
        searchText, // search text currently being entered (controlled input)
        isEditMode,
        querystring = {},
        // searchData,
        // mode = 'view',
        // variation,
    } = props;
    const { showSearchButton } = data;
    const isLive = !showSearchButton;
    const intl = useIntl();
    if (querystring?.sortable_indexes?.effective?.title) {
        querystring.sortable_indexes.effective.title = 'Publication date';
    }
    if (querystring?.sortable_indexes?.sortable_title?.title) {
        querystring.sortable_indexes.sortable_title.title = 'Sort by title';
    }
    if (querystring?.sortable_indexes?.modified?.title) {
        querystring.sortable_indexes.modified.title = 'Last edited';
    }
    return (
        <Grid className="searchBlock-facets right-column-facets" stackable>
            {data?.headline && (
                <Grid.Row>
                    <Grid.Column>
                        {data.headline && (
                            <h2 className="headline">{data.headline}</h2>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}

            <Grid.Row>
                <Grid.Column mobile={12} tablet={12} computer={12}>
                    {(Object.keys(data).includes('showSearchInput')
                        ? data.showSearchInput
                        : true) && (
                        <div className="search-wrapper">
                            <SearchInput {...props} isLive={isLive} />
                            {data.showSearchButton && (
                                <Button
                                    primary
                                    onClick={() => onTriggerSearch(searchText)}
                                    aria-label={
                                        data.searchButtonLabel ||
                                        intl.formatMessage(
                                            messages.searchButtonText,
                                        )
                                    }
                                >
                                    <span className="icon-zoom"></span>
                                </Button>
                            )}
                        </div>
                    )}

                    <div>
                        <FilterList
                            {...props}
                            isEditMode={isEditMode}
                            setFacets={setFacetsHandler(
                                setFacets,
                                onTriggerSearch,
                                searchedText,
                            )}
                        />
                    </div>
                    <div className="search-results-count-sort search-filters">
                        {/* <SearchDetails total={totalItems} /> */}
                        <div className="filters-container">
                            {data.showSortOn && (
                                <SortOn
                                    data={data}
                                    querystring={querystring}
                                    isEditMode={isEditMode}
                                    sortOrder={sortOrder}
                                    sortOn={sortOn}
                                    setSortOn={(sortOnParam) => {
                                        flushSync(() => {
                                            setSortOn(sortOnParam);
                                            onTriggerSearch(
                                                searchedText || '',
                                                facets,
                                                sortOnParam,
                                            );
                                        });
                                    }}
                                    setSortOrder={(sortOrderParam) => {
                                        flushSync(() => {
                                            setSortOrder(sortOrderParam);
                                            onTriggerSearch(
                                                searchedText || '',
                                                facets,
                                                sortOn,
                                                sortOrderParam,
                                            );
                                        });
                                    }}
                                />
                            )}
                            {data.facets?.length && (
                                <FiltersModal
                                    trigger={
                                        <div className="filters-element">
                                            <div className="filters-title listing-footer">
                                                <span className="filters-title-bold">
                                                    {data.facetsTitle}
                                                </span>
                                                <span className="right-arrow">
                                                    <Image
                                                        src={rightArrowColor}
                                                        alt={
                                                            'Albiste gehiago adierazten duen irudia'
                                                        }
                                                        width="50"
                                                        height="50"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    data={data}
                                    setFacets={setFacetsHandler(
                                        setFacets,
                                        onTriggerSearch,
                                        searchedText,
                                    )}
                                >
                                    <div
                                        id="right-modal-facets"
                                        className="facets"
                                    >
                                        <Facets
                                            querystring={querystring}
                                            data={data}
                                            facets={facets}
                                            isEditMode={isEditMode}
                                            setFacets={setFacetsHandler(
                                                setFacets,
                                                onTriggerSearch,
                                                searchedText,
                                            )}
                                            facetWrapper={FacetWrapper}
                                        />
                                    </div>
                                </FiltersModal>
                            )}
                        </div>
                    </div>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default LankuFacets;
