import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getDropdownMenuNavitems } from 'volto-dropdownmenu/actions';
import { UniversalLink } from '@plone/volto/components';
import { useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { SearchLanguage } from 'volto-lanku/components';
import config from '@plone/volto/registry';
import { usePrevious } from 'volto-lanku/components/utils';
const CustomNavigation = ({ isVisible = false, toggleVisibility }) => {
    const { settings } = config;
    const intl = useIntl();
    const { lang } = intl.locale;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDropdownMenuNavitems());
    }, [dispatch]);
    const dropdownMenuNavItems = useSelector(
        (state) => state.dropdownMenuNavItems?.result,
    );
    let location = useLocation();
    const prevLocation = usePrevious({ location });
    useEffect(() => {
        if (prevLocation && isVisible) {
            toggleVisibility();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(() => {
        isVisible
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'auto');
    }, [isVisible]);
    return (
        <div
            className={cx(
                'header-menu',
                isVisible ? 'menu-opened' : 'menu-closed',
            )}
        >
            <div className="header-navigation-menu">
                {dropdownMenuNavItems?.length > 0 &&
                    dropdownMenuNavItems[0].items.map((item, index) => {
                        return (
                            <div key={index} className="header-menu-item">
                                <NavLink
                                    to={flattenToAppURL(
                                        item.linkUrl?.[0]?.['@id'],
                                    )}
                                    key={'simplelink-' + index}
                                    className={cx('item', {
                                        [item.additionalClasses]:
                                            item.additionalClasses?.length > 0,
                                    })}
                                    activeClassName="active"
                                    exact={
                                        settings.isMultilingual
                                            ? item.linkUrl?.[0]?.['@id'] ===
                                              `/${lang}`
                                            : item.linkUrl?.[0]?.['@id'] === ''
                                    }
                                >
                                    <span>{item.title}</span>
                                </NavLink>
                            </div>
                        );
                    })}
                <UniversalLink
                    className="top-search"
                    href="/bilaketa"
                    title="Bilatu"
                >
                    Bilatu
                </UniversalLink>
            </div>

            <div className="language-social-container">
                {/* <div className="language-change-links">
                    <UniversalLink
                        className="language-change-link"
                        href="/sobre-nosotros"
                        title="ES"
                    >
                        ES
                    </UniversalLink>
                    <UniversalLink
                        className="language-change-link"
                        href="/about-us"
                        title="EN"
                    >
                        EN
                    </UniversalLink>
                    <UniversalLink
                        className="language-change-link"
                        href="/nous-sommes"
                        title="FR"
                    >
                        FR
                    </UniversalLink>
                </div> */}
                <div className="social-icons">
                    <a href={'https://www.facebook.com/lankukulturzerbitzuak'}>
                        <Icon circular inverted name="facebook f"></Icon>
                    </a>
                    <a href={'https://twitter.com/lanku_bertsoak'}>
                        <Icon circular inverted name="twitter" />
                    </a>
                    <a
                        href={`https://www.instagram.com/lankukulturzerbitzuak/`}
                    >
                        <Icon circular inverted name="instagram" />
                    </a>
                    <a
                        href={`https://www.youtube.com/channel/UCttOB-Iu9YjgxraixkFj60A`}
                    >
                        <Icon circular inverted name="youtube" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CustomNavigation;
