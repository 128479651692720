import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
// import config from '@plone/volto/registry';
import { Image } from 'semantic-ui-react';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import rightArrow from 'volto-lanku/../theme/site/assets/images/right-arrow.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import Picture from 'volto-lanku/components/Picture/Picture';
import LankuButton from 'volto-lanku/components/Blocks/LankuButtonBlock/LankuButton';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const EskaintzakListing = ({
    items,
    moreLinkText,
    moreLinkUrl,
    header,
    headerUrl,
    headerTag,
    isEditMode,
}) => {
    let moreLink = null;
    let moreHref = moreLinkUrl?.[0]?.['@id'] || '';
    const HeaderTag = headerTag ? headerTag : 'h3';
    if (isInternalURL(moreHref)) {
        moreLink = (
            <ConditionalLink
                to={flattenToAppURL(moreHref)}
                condition={!isEditMode}
            >
                <span className="listing-footer-text">
                    {moreLinkText || moreHref}
                </span>
                <span className="right-arrow">
                    <Image
                        src={rightArrow}
                        alt={'Saio gehiago adierazten duen irudia'}
                        width="50"
                        height="50"
                    />
                </span>
            </ConditionalLink>
        );
    } else if (moreHref) {
        moreLink = <a href={moreHref}>{moreLinkText || moreHref}</a>;
    }

    let headerLink = null;
    let headerHref = headerUrl?.[0]?.['@id'] || '';
    if (isInternalURL(headerHref)) {
        headerLink = (
            <ConditionalLink
                to={flattenToAppURL(headerHref)}
                condition={!isEditMode}
            >
                {header || headerHref}
            </ConditionalLink>
        );
    } else if (headerHref) {
        headerLink = <a href={headerHref}>{header || headerHref}</a>;
    }
    return (
        <>
            <HeaderTag className="listing-header">
                {headerLink ? headerLink : header}
            </HeaderTag>
            <div className="container">
                {items.map((item) => (
                    <div
                        className="eskaintzak-grid-container"
                        key={item['@id']}
                    >
                        <div className="eskaintza-grid-content">
                            <h3>{item.title}</h3>
                            <p className="description documentDescription">
                                {item.description}
                            </p>
                            <LankuButton
                                variation={'secondary'}
                                size={'big'}
                                link={item['@id']}
                            >
                                Info gehiago
                            </LankuButton>
                        </div>
                        <div className="eskaintza-grid-image">
                            {!item.image_field && (
                                <ConditionalLink
                                    item={item}
                                    condition={!isEditMode}
                                >
                                    <Image
                                        src={DefaultImageSVG}
                                        alt="This content has no image, this is a default placeholder."
                                        size="small"
                                    />
                                </ConditionalLink>
                            )}
                            {item.image_field && (
                                <ConditionalLink
                                    item={item}
                                    condition={!isEditMode}
                                >
                                    <Picture
                                        source="eskaintzagrid"
                                        imageBase={`${item['@id']}/@@images/image`}
                                        alt={item.title}
                                    ></Picture>
                                </ConditionalLink>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {moreLink && <div className="listing-footer">{moreLink}</div>}
        </>
    );
};
EskaintzakListing.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default EskaintzakListing;
