export const LehenDestakatuaSchema = (config) => {
    function getCardSchema() {
        return {
            title: 'Card',
            fieldsets: [
                {
                    id: 'default',
                    title: 'Default',
                    fields: ['title', 'buttonText', 'buttonLink'],
                },
            ],

            properties: {
                title: {
                    title: 'Testua',
                    type: 'string',
                    //widget: 'richtext',
                },
                buttonText: {
                    title: 'Botoiaren testua',
                    type: 'string',
                },
                buttonLink: {
                    title: 'Botoiaren url-a',
                    /* widget: 'object_browser',
                    mode: 'link',
                    selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
                    allowExternals: true, */
                },
            },

            required: [],
        };
    }
    return {
        title: 'Card container',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['kokapena', 'telefonoa', 'email', 'image', 'info'],
            },
        ],
        properties: {
            kokapena: {
                title: 'Kokapena',
                /* description: 'Kokapena', */
                type: 'string',
            },
            telefonoa: {
                title: 'Telefonoa',
                /* description: 'Telefonoa', */
                type: 'string',
            },
            email: {
                title: 'Email-a',
                /* description: 'Email-a', */
                type: 'string',
            },
            image: {
                title: 'Irudia',
                widget: 'attachedimage',
            },
            info: {
                title: 'Txartela',
                widget: 'object_list',
                schema: getCardSchema(),
            },
        },
        required: [],
    };
};
