import messages from '@codesyntax/volto-listingadvanced-variation/messages';

const LankuListingSchema = (props) => {
    const { intl, schema, formData } = props;
    const headingChoices = [
        ['h2', 'H2'],
        ['h3', 'H3'],
        ['h4', 'H4'],
    ];
    let heading =
        formData && formData['@type'] === 'listing'
            ? [
                  {
                      id: 'header',
                      title: intl.formatMessage(messages.headerConfiguration),
                      fields: ['header', 'headerUrl', 'headerTag'],
                  },
              ]
            : [];
    const include_fieldsets = ['default', 'searchquery', 'facets', 'controls'];
    return {
        ...schema,
        fieldsets: [
            ...(schema.fieldsets &&
                schema.fieldsets.length > 0 &&
                schema.fieldsets.filter((fieldset) =>
                    include_fieldsets.includes(fieldset.id),
                )),
            ...heading,
            {
                id: 'moreLink',
                title: intl.formatMessage(messages.moreLinkConfiguration),
                fields: ['moreLinkText', 'moreLinkUrl'],
            },
        ],
        properties: {
            ...schema.properties,
            header: {
                title: intl.formatMessage(messages.header),
                description: intl.formatMessage(messages.headerDescription),
            },
            headerUrl: {
                title: intl.formatMessage(messages.headerUrl),
                description: intl.formatMessage(messages.headerUrlDescription),
                widget: 'object_browser',
                mode: 'link',
                allowExternals: true,
            },
            headerTag: {
                title: intl.formatMessage(messages.headerTag),
                description: intl.formatMessage(messages.headerTagDescription),
                choices: [['h1', 'H1'], ...headingChoices],
            },
            moreLinkText: {
                title: intl.formatMessage(messages.moreLinkText),
                description: intl.formatMessage(
                    messages.moreLinkTextDescription,
                ),
            },
            moreLinkUrl: {
                title: intl.formatMessage(messages.moreLinkUrl),
                description: intl.formatMessage(
                    messages.moreLinkUrlDescription,
                ),
                widget: 'object_browser',
                mode: 'link',
                allowExternals: true,
            },
        },
    };
};

export default LankuListingSchema;
