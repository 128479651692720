import { defineMessages } from 'react-intl';

const messages = defineMessages({
    phone: {
        id: 'phone',
        defaultMessage: '943 26 07 55',
    },
    email: {
        id: 'email',
        defaultMessage: 'lanku@lanku.eus',
    },
    privacy_policy: {
        id: 'privacy_policy',
        defaultMessage: 'Pribatutasun politika',
    },
    cookie: {
        id: 'cookie',
        defaultMessage: 'Cookie politika',
    },
    legal_notice: {
        id: 'legal_notice',
        defaultMessage: 'Legezko oharra',
    },
    contact: {
        id: 'contact',
        defaultMessage: 'Kontaktua',
    },
    privacy_policy_link: {
        id: 'privacy_policy_link',
        defaultMessage: '/pribatutasun-politika',
    },
    cookie_link: {
        id: 'cookie_link',
        defaultMessage: '/cookieen-politika',
    },
    legal_notice_link: {
        id: 'legal_notice_link',
        defaultMessage: '/lege-oharra',
    },
    contact_link: {
        id: 'contact_link',
        defaultMessage: '/kontaktua',
    },
    applyFilters: {
        id: 'applyFilters',
        defaultMessage: 'Apply filters',
    },
});
export default messages;
