import React, { useState } from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import config from '@plone/volto/registry';
import EskaintzaVideosBlockView from './EskaintzaVideosBlockView';
import { useSelector } from 'react-redux';
const EskaintzaVideosBlockEdit = (props) => {
    const { data = {}, block = null, onChangeBlock, selected } = props;
    const [selectedItemBlock, setSelectedItemBlock] = useState(-1);
    const content = useSelector((state) => state.content.data);
    return (
        <>
            <div
                className="eskaintza-related-videos"
                onClick={() => {
                    props.setSidebarTab(1);
                    setSelectedItemBlock(-1);
                }}
                aria-hidden="true"
            ></div>
            <EskaintzaVideosBlockView
                {...props}
                isEditMode={true}
            ></EskaintzaVideosBlockView>
        </>
    );
};

export default EskaintzaVideosBlockEdit;
