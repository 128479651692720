/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import messages from 'volto-lanku/components/Utils/overrideMessages.js';
import { useGoogleAnalytics } from 'volto-google-analytics';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();

  return (
    <Segment role="contentinfo" vertical padded textAlign="center" id="footer">
      <Container>
        <Segment basic className="discreet">
          Lanku kultur zerbitzuak -{' '}
          <strong>{intl.formatMessage(messages.phone)}</strong>{' '}
          {intl.formatMessage(messages.email)}
        </Segment>
        <List horizontal>
          {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={intl.formatMessage(messages.contact_link)}
            >
              {intl.formatMessage(messages.contact)}
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={intl.formatMessage(messages.legal_notice_link)}
            >
              {intl.formatMessage(messages.legal_notice)}
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={intl.formatMessage(messages.privacy_policy_link)}
            >
              {intl.formatMessage(messages.privacy_policy)}
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={intl.formatMessage(messages.cookie_link)}
            >
              {intl.formatMessage(messages.cookie)}
            </UniversalLink>
          </div>
        </List>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
