import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
// import config from '@plone/volto/registry';
import rightArrow from 'volto-lanku/../theme/site/assets/images/right-arrow.svg';
import { Image } from 'semantic-ui-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers';
const messages = defineMessages({
    startTime: {
        id: '{date} -tik',
        defaultMessage: '{date} -tik',
    },
});

const AgendaListing = ({
    items,
    moreLinkText,
    moreLinkUrl,
    header,
    headerUrl,
    headerTag,
    isEditMode,
}) => {
    const intl = useIntl();

    const lang = useSelector((state) => state.intl.locale);
    moment.locale(lang);
    let moreLink = null;
    let moreHref = moreLinkUrl?.[0]?.['@id'] || '';
    const HeaderTag = headerTag ? headerTag : 'h3';
    if (isInternalURL(moreHref)) {
        moreLink = (
            <ConditionalLink
                to={flattenToAppURL(moreHref)}
                condition={!isEditMode}
            >
                <span className="listing-footer-text">
                    {moreLinkText || moreHref}
                </span>
                <span className="right-arrow">
                    <Image
                        src={rightArrow}
                        alt={'Albiste gehiago adierazten duen irudia'}
                        width="50"
                        height="50"
                    />
                </span>
            </ConditionalLink>
        );
    } else if (moreHref) {
        moreLink = <a href={moreHref}>{moreLinkText || moreHref}</a>;
    }

    let headerLink = null;
    let headerHref = headerUrl?.[0]?.['@id'] || '';
    if (isInternalURL(headerHref)) {
        headerLink = (
            <ConditionalLink
                to={flattenToAppURL(headerHref)}
                condition={!isEditMode}
            >
                {header || headerHref}
            </ConditionalLink>
        );
    } else if (headerHref) {
        moreLink = <a href={headerHref}>{moreLinkText || headerHref}</a>;
    }
    return (
        <>
            <HeaderTag className="listing-header">
                {headerLink ? headerLink : header}
            </HeaderTag>
            <div className="container">
                <div className="agenda-grid-container">
                    {items.map((item) => (
                        <div className="agenda-grid-element" key={item['@id']}>
                            {!item.link && (
                                <ConditionalLink
                                    item={item}
                                    condition={!isEditMode}
                                >
                                    <div className="agenda-date">
                                        <span className="month">
                                            {moment(item.start).format('MMMM')}
                                        </span>
                                        <span className="day">
                                            {moment(item.start).format('D')}
                                        </span>
                                    </div>
                                    {item.location && (
                                        <div className="agenda-location-container">
                                            <span className="agenda-location"></span>
                                            <h3>{item.location}</h3>
                                        </div>
                                    )}
                                    <div className="agenda-title-container">
                                        <span className="agenda-subject"></span>
                                        <h3>{item.title}</h3>
                                    </div>
                                </ConditionalLink>
                            )}
                            {item.link && (
                                <ConditionalLink
                                    item={item}
                                    condition={!isEditMode}
                                    to={item.link}
                                    openLinkInNewTab={false}
                                >
                                    <div className="agenda-date">
                                        <span className="month">
                                            {moment(item.start).format('MMMM')}
                                        </span>
                                        <span className="day">
                                            {moment(item.start).format('D')}
                                        </span>
                                    </div>
                                    {item.location && (
                                        <div className="agenda-location-container">
                                            <span className="agenda-location"></span>
                                            <h3>{item.location}</h3>
                                        </div>
                                    )}
                                    <div className="agenda-title-container">
                                        <span className="agenda-subject"></span>
                                        <h3>{item.title}</h3>
                                    </div>
                                </ConditionalLink>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {moreLink && <div className="listing-footer">{moreLink}</div>}
        </>
    );
};
AgendaListing.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default AgendaListing;
