import React from 'react';
import TeaserBody from '@kitconcept/volto-blocks-grid/components/Teaser/Body';
import ImageLink from './ImageLink';
import { withBlockExtensions } from '@plone/volto/helpers';

const TeaserView = (props) => {
    const { data } = props;
    return (
        <>
            {/* {data?.variation === 'allContentLink' && <TeaserBody {...props} />}

            {data?.variation === 'imageLink' && ( */}
            <ImageLink {...props}></ImageLink>
            {/* )} */}
        </>
    );
};

export default withBlockExtensions(TeaserView);
