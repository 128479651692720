import React, { useState } from 'react';
import EskaintzaMainContentSchema from './EskaintzaMainContentSchema';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import config from '@plone/volto/registry';
import EskaintzaMainContentBlockView from './EskaintzaMainContentBlockView';
import { useSelector } from 'react-redux';
const EskaintzaMainContentBlockEdit = (props) => {
    const { data = {}, block = null, onChangeBlock, selected } = props;
    const [selectedItemBlock, setSelectedItemBlock] = useState(-1);
    const content = useSelector((state) => state.content.data);
    return (
        <>
            <div
                className="eskaintza-main-content-header"
                onClick={() => {
                    props.setSidebarTab(1);
                    setSelectedItemBlock(-1);
                }}
                aria-hidden="true"
            ></div>
            <EskaintzaMainContentBlockView
                {...props}
                isEditMode={true}
            ></EskaintzaMainContentBlockView>
            <SidebarPortal selected={selected && selectedItemBlock === -1}>
                <BlockDataForm
                    title="Eskaintzaren multimedia nagusia"
                    schema={EskaintzaMainContentSchema(config, content)}
                    onChangeField={(id, value) => {
                        onChangeBlock(block, {
                            ...data,
                            [id]: value,
                        });
                    }}
                    formData={data}
                    block={block}
                />
            </SidebarPortal>
        </>
    );
};

export default EskaintzaMainContentBlockEdit;
