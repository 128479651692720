export const BuletinFormSchema = (config) => {
    return {
        title: 'Buletin Form',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['buttonText'],
            },
        ],
        properties: {
            buttonText: {
                title: 'Button text',
                type: 'string',
            },
        },
        required: [],
    };
};
