const EskaintzaMainContentSchema = (config, content) => {
    const choices = [
        ['image', 'Irudia'],
        ...(content?.main_video_file ? [['video', 'Bideoa']] : []),
    ];
    return {
        title: 'Eskaintzaren multimedia nagusia',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['type'],
            },
        ],
        properties: {
            type: {
                title: 'Multimedia mota',
                type: 'array',
                choices: choices,
                required: true,
            },
        },
        required: [],
    };
};

export default EskaintzaMainContentSchema;
