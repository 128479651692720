import { Grid, Button } from 'semantic-ui-react';
import Picture from 'volto-lanku/components/Picture/Picture';

const LehenDestakatua = (props) => {
    const {
        kokapena,
        telefonoa,
        email,
        image,
        title,
        info,
        isEditMode,
    } = props;
    return (
        <Grid className="home-main-featured">
            <Grid.Row>
                <Grid.Column width={3} verticalAlign={'middle'}>
                    <Grid.Row className="kokapena">{kokapena}</Grid.Row>
                    <Grid.Row className="telefonoa">{telefonoa}</Grid.Row>
                    <Grid.Row className="email">{email}</Grid.Row>
                </Grid.Column>
                <Grid.Column width={9}>
                    <Grid.Row>
                        <Grid.Column>
                            {image && (
                                <>
                                    <Picture
                                        source="grid"
                                        imageBase={`${image}/@@images/image`}
                                        lazy={true}
                                    />
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={9}>
                    {info?.map((item, key) => (
                        <Grid key={key} className="Lehen-destakatua-item">
                            {item.title && item.buttonText && item.buttonLink && (
                                <Grid.Row>
                                    <Grid.Column width={7}>
                                        {item.title}
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        {isEditMode ? (
                                            <Button>{item.buttonText}</Button>
                                        ) : (
                                            <Button href={item.buttonLink}>
                                                {item.buttonText}
                                            </Button>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    ))}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default LehenDestakatua;
