import React from 'react';
import Picture from 'volto-lanku/components/Picture/Picture';
import Video from 'volto-lanku/components/Video/Video';
import { useSelector } from 'react-redux';

const EskaintzaMainContentBlockView = (props) => {
    const { data, isEditMode = false } = props;
    const content = useSelector((state) => state.content.data);
    return (
        <>
            <div className="eskaintza-main-content">
                {!data.type && isEditMode && (
                    <div>Ez duzu multimedia nagusiaren mota aukeratu</div>
                )}

                {data.type === 'image' && content.image && (
                    <Picture
                        className="eskaintza-image"
                        source="eskaintza"
                        imageBase={`${content['@id']}/@@images/image`}
                        alt={content.title}
                        lazy={false}
                        content={content}
                    ></Picture>
                )}
                {data.type === 'video' && content.image && (
                    <Video
                        className="eskaintza-video"
                        src={`${content['@id']}/@@download/main_video_file`}
                    ></Video>
                )}
            </div>
        </>
    );
};

export default EskaintzaMainContentBlockView;
