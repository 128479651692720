import customBlocks from 'volto-lanku/components/customBlocks';
import BertsolariaView from 'volto-lanku/components/Views/BertsolariaView';
const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    nonContentRoutes: [...config.settings.nonContentRoutes],
    defaultLanguage: 'eu',
    registry: {
      ...config.settings.registry,
    },

    showTags: false,
    pictureOptions: {
      grid: [
        { media: '(min-width: 768px)', image: 'large' },
        { media: '(max-width: 767px)', image: 'large' },
      ],
      eskaintza: [
        { media: '(min-width: 1200px)', image: 'huge' },
        {
          media: '(min-width: 992px) and (max-width: 1199px)',
          image: 'larger',
        },
        { media: '(max-width: 991px)', image: 'large' },
      ],
      eskaintzagrid: [
        { media: '(min-width: 1200px)', image: 'larger' },
        {
          media: '(max-width: 1199px)',
          image: 'large',
        },
      ],
      newsitem: [
        { media: '(min-width: 1200px)', image: 'larger' },
        {
          media: '(min-width: 1199px)',
          image: 'large',
        },
      ],
    },
  };
  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    ...customBlocks(config),
  };
  // This disables the `@kitconcept/volto-blocks-grid` grid block
  config.blocks.blocksConfig.__grid.restricted = true;
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      Bertsolaria: BertsolariaView,
    },
  };

  return config;
};
export default applyConfig;
