import { SelectFacetFilterListEntry } from '@plone/volto/components/manage/Blocks/Search/components';

import EskaintzaMainContentBlockEdit from 'volto-lanku/components/Blocks/EskaintzaMainContentBlock/EskaintzaMainContentBlockEdit';
import EskaintzaMainContentBlockView from 'volto-lanku/components/Blocks/EskaintzaMainContentBlock/EskaintzaMainContentBlockView';
import EskaintzaVideosBlockView from 'volto-lanku/components/Blocks/EskaintzaVideosBlock/EskaintzaVideosBlockView';
import EskaintzaVideosBlockEdit from 'volto-lanku/components/Blocks/EskaintzaVideosBlock/EskaintzaVideosBlockEdit';
import LankuButtonBlockEdit from 'volto-lanku/components/Blocks/LankuButtonBlock/LankuButtonBlockEdit';
import LankuButtonBlockView from 'volto-lanku/components/Blocks/LankuButtonBlock/LankuButtonBlockView';
import {
    LehenDestakatuaBlockView,
    LehenDestakatuaBlockEdit,
    BuletinFormBlockEdit,
    BuletinFormBlockView,
} from 'volto-lanku/components/Blocks';

import {
    LankuListingSchema,
    BertsolariakListing,
    AlbisteakListing,
    EskaintzakListing,
    AgendaListing,
    LankuFacets,
    CheckBoxTreeFacet,
    WithType,
    TeaserView,
} from 'volto-lanku/components/Variations';
import navSVG from '@plone/volto/icons/nav.svg';
const customBlocks = (config) => ({
    ...config.blocks.blocksConfig,

    eskaintzaMainContent: {
        id: 'eskaintzaMainContent', // The name (id) of the block
        title: 'Eskaintzaren multimedia nagusia', // The display name of the block
        icon: navSVG, // The icon used in the block chooser
        group: 'common', // The group (blocks can be grouped, displayed in the chooser)
        view: EskaintzaMainContentBlockView, // The view mode component
        edit: EskaintzaMainContentBlockEdit, // The edit mode component
        restricted: false, // {Boolean|function} If the block is restricted, it won't show in the chooser. The function signature is `({properties, block})` where `properties` is the current object data and `block` is the block being evaluated in `BlockChooser`.
        mostUsed: true, // A meta group `most used`, appearing at the top of the chooser
        blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
        sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
        security: {
            addPermission: [], // Future proof (not implemented yet) add user permission role(s)
            view: [], // Future proof (not implemented yet) view user role(s)
        },
    },
    leadimage: {
        ...config.blocks.blocksConfig.leadimage,
        restricted: false,
    },
    eskaintzaVideos: {
        id: 'eskaintzaVideos', // The name (id) of the block
        title: 'Eskaintzaren bideo erlazionatuak', // The display name of the block
        icon: navSVG, // The icon used in the block chooser
        group: 'common', // The group (blocks can be grouped, displayed in the chooser)
        view: EskaintzaVideosBlockView, // The view mode component
        edit: EskaintzaVideosBlockEdit, // The edit mode component
        restricted: false, // {Boolean|function} If the block is restricted, it won't show in the chooser. The function signature is `({properties, block})` where `properties` is the current object data and `block` is the block being evaluated in `BlockChooser`.
        mostUsed: true, // A meta group `most used`, appearing at the top of the chooser
        blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
        sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
        security: {
            addPermission: [], // Future proof (not implemented yet) add user permission role(s)
            view: [], // Future proof (not implemented yet) view user role(s)
        },
    },
    lankuButton: {
        id: 'lankuButton',
        title: 'Lanku Button',
        icon: navSVG,
        group: 'common',
        view: LankuButtonBlockView,
        edit: LankuButtonBlockEdit,
        restricted: false, // If the block is restricted, it won't show in the chooser
        mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
        blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
        sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
        security: {
            addPermission: [], // Future proof (not implemented yet) add user permission role(s)
            view: [], // Future proof (not implemented yet) view user role(s)
        },
        variation: [
            {
                id: 'primary',
                isDefault: true,
                title: 'Primary',
            },
            {
                id: 'secondary',
                isDefault: false,
                title: 'Secondary',
            },
        ],
        size: [
            {
                id: 'large',
                isDefault: true,
                title: 'Large',
            },
            {
                id: 'medium',
                isDefault: false,
                title: 'Medium',
            },
            {
                id: 'small',
                isDefault: false,
                title: 'Small',
            },
            {
                id: 'tiny',
                isDefault: false,
                title: 'Tiny',
            },
        ],
    },
    __grid: {
        ...config.blocks.blocksConfig.__grid,
        gridAllowedBlocks: [
            'image',
            'listing',
            'slate',
            'text',
            'homeFeatured',
            'lankuButton',
        ],
        maxNumberOfColumns: 16,
    },
    listing: {
        ...config.blocks.blocksConfig.listing,
        variations: [
            ...config.blocks.blocksConfig.listing.variations,
            {
                id: 'eskaintzak',
                title: 'Eskaintzak',
                template: EskaintzakListing,
                schemaEnhancer: LankuListingSchema,
            },
            {
                id: 'bertsolariak',
                title: 'Bertsolariak',
                template: BertsolariakListing,
                schemaEnhancer: LankuListingSchema,
            },
            {
                id: 'albisteak',
                title: 'Albisteak',
                template: AlbisteakListing,
                schemaEnhancer: LankuListingSchema,
            },
            {
                id: 'agenda',
                title: 'Agenda',
                template: AgendaListing,
                schemaEnhancer: LankuListingSchema,
            },
        ],
    },
    search: {
        ...config.blocks.blocksConfig.search,
        variations: [
            {
                id: 'LankuFacets',
                title: 'Facets Lanku',
                view: LankuFacets,
                isDefault: false,
            },
            ...config.blocks.blocksConfig.search.variations,
        ],
        extensions: {
            ...config.blocks.blocksConfig.search.extensions,
            facetWidgets: {
                ...config.blocks.blocksConfig.search.extensions.facetWidgets,
                rewriteOptions: (name, choices) => {
                    return name === 'review_state'
                        ? choices.map((opt) => ({
                              ...opt,
                              label: opt.label.replace(/\[.+\]/, '').trim(),
                          }))
                        : choices;
                },
                types: [
                    {
                        id: 'checkboxTreeFacet',
                        title: 'Checkbox Tree',
                        view: WithType(CheckBoxTreeFacet, 'checkbox'),
                        isDefault: false,
                        schemaEnhancer: CheckBoxTreeFacet.schemaEnhancer,
                        stateToValue: CheckBoxTreeFacet.stateToValue,
                        valueToQuery: CheckBoxTreeFacet.valueToQuery,
                        filterListComponent: SelectFacetFilterListEntry,
                    },

                    ...config.blocks.blocksConfig.search.extensions.facetWidgets
                        .types,
                ],
            },
        },
    },
    teaser: {
        ...config.blocks.blocksConfig.teaser,
        imageScale: 'teaser',
        view: TeaserView,
    },
    lehenDestakatua: {
        id: 'lehenDestakatua', // The name (id) of the block
        title: 'Lehen destakatua', // The display name of the block
        icon: navSVG, // The icon used in the block chooser
        group: 'common', // The group (blocks can be grouped, displayed in the chooser)
        view: LehenDestakatuaBlockView, // The view mode component
        edit: LehenDestakatuaBlockEdit, // The edit mode component
        restricted: false, // {Boolean|function} If the block is restricted, it won't show in the chooser. The function signature is `({properties, block})` where `properties` is the current object data and `block` is the block being evaluated in `BlockChooser`.
        mostUsed: true, // A meta group `most used`, appearing at the top of the chooser
        blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
        sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
        security: {
            addPermission: [], // Future proof (not implemented yet) add user permission role(s)
            view: [], // Future proof (not implemented yet) view user role(s)
        },
    },
    buletinForm: {
        id: 'buletinForm', // The name (id) of the block
        title: 'Buletin Formulario', // The display name of the block
        icon: navSVG, // The icon used in the block chooser
        group: 'common', // The group (blocks can be grouped, displayed in the chooser)
        view: BuletinFormBlockView, // The view mode component
        edit: BuletinFormBlockEdit, // The edit mode component
        restricted: false, // If the block is restricted, it won't show in the chooser
        mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
        blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
        sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
        security: {
            addPermission: [], // Future proof (not implemented yet) add user permission role(s)
            view: [], // Future proof (not implemented yet) view user role(s)
        },
    },
});

export default customBlocks;
