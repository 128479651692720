import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { BuletinFormSchema } from './BuletinFormBlockSchema';
import BuletinForm from './BuletinForm';

const BuletinFormBlockEdit = (props) => {
    const { block, data, onChangeBlock, selected, editable } = props;
    return (
        <>
            <BuletinForm
                buttonText={data.buttonText}
                isEditMode={editable}
            ></BuletinForm>
            <SidebarPortal selected={selected}>
                <BlockDataForm
                    schema={BuletinFormSchema(config)}
                    title="Buletin Form"
                    onChangeField={(id, value) => {
                        onChangeBlock(block, {
                            ...data,
                            [id]: value,
                        });
                    }}
                    formData={data}
                    block={block}
                />
            </SidebarPortal>
        </>
    );
};

export default BuletinFormBlockEdit;
