import { useEffect, useRef } from 'react';

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

/**
 * Slugify a string: remove whitespaces, special chars and replace with _
 * @param {string} string String to be slugified
 * @returns {string} Slugified string
 */
export const slugify = (string) => {
    return string
        .toLowerCase()
        .replace(/[\s-]+/g, '_')
        .replace(/[^\w]+/g, '');
};
