/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomNavigation } from 'volto-lanku/components';
import { Logo } from '@plone/volto/components';
import cx from 'classnames';
/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
    /**
     * Property types.
     * @property {Object} propTypes Property types.
     * @static
     */
    static propTypes = {
        token: PropTypes.string,
        pathname: PropTypes.string.isRequired,
    };

    /**
     * Default properties.
     * @property {Object} defaultProps Default properties.
     * @static
     */
    static defaultProps = {
        token: null,
    };
    state = {
        isVisibleMenu: false,
    };
    /**
     * Render method.
     * @method render
     * @returns {string} Markup for the component.
     */
    render() {
        return (
            <Segment
                basic
                className={cx(
                    'header-wrapper',
                    this.props.header?.fixedstatus,
                    this.state.isVisibleMenu ? 'menu-opened' : 'menu-closed',
                )}
                role="banner"
            >
                <div className="header">
                    <div className="logo-nav-wrapper">
                        <div className="logo">
                            <Logo />
                        </div>
                        <div className="right-wrapper">
                            <div className="icons">
                                {!this.state.isVisibleMenu && (
                                    <Icon
                                        onClick={() => {
                                            this.setState({
                                                isVisibleMenu: !this.state
                                                    .isVisibleMenu,
                                            });
                                        }}
                                        className="lanku-icon menu"
                                        name={'bars'}
                                        size="big"
                                    />
                                )}
                                {this.state.isVisibleMenu && (
                                    <Icon
                                        onClick={() => {
                                            this.setState({
                                                isVisibleMenu: !this.state
                                                    .isVisibleMenu,
                                            });
                                        }}
                                        className="lanku-icon menu"
                                        name={'close'}
                                        size="big"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <CustomNavigation
                        isVisible={this.state.isVisibleMenu}
                        toggleVisibility={() => {
                            this.setState({
                                isVisibleMenu: !this.state.isVisibleMenu,
                            });
                        }}
                    />
                </div>
            </Segment>
        );
    }
}

export default connect((state) => ({
    token: state.userSession.token,
    header: state.content?.data?.['@components']?.header,
}))(Header);
